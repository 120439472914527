import {gsap} from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import { HashLink as Link } from 'react-router-hash-link';

export default function Menu(){
    function openMenuNav(){
        let tab = document.querySelectorAll("nav a")
            document.querySelector('header')?.classList.add("backdrop-blur-[2px]")
            gsap.to("#menu", {
                rotate: 360,
                duration: 0.5,
                opacity: 0
            })
            gsap.to("#clear", {
                rotate: 180,
                duration: 0.5,
                opacity: 1,
                delay: 0.2,
                display: "flex"
            })
            let delai = 0
            tab.forEach(function (a) {
                gsap.to(a, {
                    opacity: 1,
                    display: "inline-block",
                    top: 0,
                    duration: 0.5,
                    delay: delai,
                    ease: "power2.out"
                })
                delai +=0.2
            })
    }

    openMenuNav()

    function closeMenuNav(){
        let tab = document.querySelectorAll("nav a")
            gsap.to("#menu", {
                rotate: 0,
                duration: 0.5,
                delay: 0.2,
                opacity: 1
            })
            gsap.to("#clear", {
                rotate: -180,
                duration: 0.5,
                opacity: 0,
                display: "none"
            })
            let delai = 0
            tab.forEach(function (a) {
                gsap.to(a, {
                    opacity: 0,
                    display: "hidden",
                    top: "-80px",
                    duration: 0.5,
                    delay: delai,
                    ease: "power1.inOut"
                })
                delai +=0.2
            })
            document.querySelector("header")?.classList.remove("backdrop-blur-[2px]")
    }
    closeMenuNav()

    function openBurger(){
        document.getElementById("burger").classList.remove("top-[-100vh]")
        document.getElementById("burger").classList.add("top-0")
    }

    function closeBurger(){
        document.getElementById("burger").classList.add("top-[-100vh]")
        document.getElementById("burger").classList.remove("top-0")
    }

    return(
        <>
            <header className="hidden fixed md:flex items-center justify-between w-full top-0 z-50 p-[24px]">
                <div className="flex items-center">
                    <img className="h-[48px] lg:h-[64px] mr-[48px]" src="/img/logo.svg"/>
                    <nav className="relative grow flex justify-around">
                        <div className="text-center "><Link className="hidden [text-shadow:_1px_1px_3px_rgb(0_0_0_/_100%)] relative top-[-80px] pr-[30px] lg:pr-[50px] xl:pr-[80px] w-full font-nav  transition-all duration-250 hover:tracking-h3 uppercase text-white font-light after:content-[''] after:transition-all after:duration-500 after:w-0 after:hover:w-full after:hover:w-menu after:absolute  after:left-0 after:bottom-0 after:h-[1px]" to="/#accueil">Accueil</Link></div>
                        <div className="text-center"><Link className="hidden [text-shadow:_1px_1px_3px_rgb(0_0_0_/_100%)] relative top-[-80px] pr-[30px] lg:pr-[50px] xl:pr-[80px] w-full font-nav  transition-all duration-250 hover:tracking-h3 uppercase text-white font-light after:content-[''] after:transition-all after:duration-500 after:w-0 after:hover:w-full after:hover:w-menu after:absolute after:left-0 after:bottom-0 after:h-[1px]" to="/#a-propos">à propos</Link></div>
                        <div className="text-center"><Link className="hidden [text-shadow:_1px_1px_3px_rgb(0_0_0_/_100%)] relative top-[-80px] pr-[30px] lg:pr-[50px] xl:pr-[80px] w-full font-nav  transition-all duration-250 hover:tracking-h3 uppercase text-white font-light after:content-[''] after:transition-all after:duration-500 after:w-0 after:hover:w-full after:hover:w-menu after:absolute  after:left-0 after:bottom-0 after:h-[1px]" to="/realisations">Réalisations</Link></div>
                        <div className="text-center"><Link className="hidden [text-shadow:_1px_1px_3px_rgb(0_0_0_/_100%)] relative top-[-80px] pr-[30px] lg:pr-[50px] xl:pr-[80px] w-full font-nav  transition-all duration-250 hover:tracking-h3 uppercase text-white font-light after:content-[''] after:transition-all after:duration-500 after:w-0 after:hover:w-full after:hover:w-menu after:absolute  after:left-0 after:bottom-0 after:h-[1px]" to="/#competences">Compétences</Link></div>
                        <div className="text-center"><Link className="hidden [text-shadow:_1px_1px_3px_rgb(0_0_0_/_100%)] relative top-[-80px] pr-[30px] lg:pr-[50px] xl:pr-[80px] w-full font-nav transition-all duration-250 hover:tracking-h3 uppercase text-white font-light after:content-[''] after:transition-all after:duration-500 after:w-0 after:hover:w-full after:hover:w-menu after:absolute after:left-0 after:bottom-0 after:h-[1px]" to="/#contact">Contact</Link></div>
                    </nav>
                </div>
                <img id="menu" onClick={openMenuNav} className="h-[48px] lg:h-[64px] absolute right-[24px] cursor-pointer" src="/img/menu.svg"/>
                <div id="clear" onClick={closeMenuNav} className="w-[64px] h-[64px] opacity-0 hidden cursor-pointer justify-center items-center"><img className="relative my-auto" src="/img/clear.svg"/></div>
            </header>

            <header className="flex fixed md:hidden items-center justify-between w-full top-0 z-50 p-[24px]">
                <img className="h-[48px] mr-[48px]" src="/img/logo.svg"/>
                <img id="menu" onClick={openBurger} className="h-[48px] absolute right-[24px] cursor-pointer" src="/img/menu.svg"/>
                <div id="burger" className="top-[-100vh] duration-500 transition-all p-[32px] flex flex-col items-center justify-between w-full h-screen fixed left-0 bg-red-trans">
                    <svg onClick={closeBurger} className="mx-auto" width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M32.0998 27.84C32.3522 28.0904 32.4942 28.4312 32.4942 28.7867C32.4942 29.1422 32.3522 29.483 32.0998 29.7333L30.2331 31.6C29.9827 31.8524 29.642 31.9944 29.2864 31.9944C28.9309 31.9944 28.5901 31.8524 28.3398 31.6L16.4998 19.76L4.65977 31.6C4.40941 31.8524 4.06862 31.9944 3.7131 31.9944C3.35759 31.9944 3.01679 31.8524 2.76644 31.6L0.899769 29.7333C0.647352 29.483 0.505371 29.1422 0.505371 28.7867C0.505371 28.4312 0.647352 28.0904 0.899769 27.84L12.7398 16L0.899769 4.16001C0.647352 3.90966 0.505371 3.56886 0.505371 3.21335C0.505371 2.85783 0.647352 2.51704 0.899769 2.26668L2.76644 0.400013C3.01679 0.147596 3.35759 0.00561523 3.7131 0.00561523C4.06862 0.00561523 4.40941 0.147596 4.65977 0.400013L16.4998 12.24L28.3398 0.400013C28.5901 0.147596 28.9309 0.00561523 29.2864 0.00561523C29.642 0.00561523 29.9827 0.147596 30.2331 0.400013L32.0998 2.26668C32.3522 2.51704 32.4942 2.85783 32.4942 3.21335C32.4942 3.56886 32.3522 3.90966 32.0998 4.16001L20.2598 16L32.0998 27.84Z"
                            fill="#E9EAEC"/>
                    </svg>
                    <nav className="w-full flex justify-center grow my-4 flex-col text-center">
                        <Link onClick={closeBurger} className="text-white text-h3 uppercase font-bold my-4" to="/#accueil">Accueil</Link>
                        <Link onClick={closeBurger}  className="text-white text-h3 uppercase font-bold my-4" to="/#a-propos">À propos</Link>
                        <Link onClick={closeBurger}  className="text-white text-h3 uppercase font-bold my-4" to="/realisations">Réalisations</Link>
                        <Link onClick={closeBurger}  className="text-white text-h3 uppercase font-bold my-4" to="/#competences">Compétences</Link>
                        <Link onClick={closeBurger}  className="text-white text-h3 uppercase font-bold my-4" to="/#contact">Contact</Link>
                    </nav>

                    <svg className="mx-auto" width="103" height="64" viewBox="0 0 103 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.8721 12.9829V64L19.7673 12.9829H0.258545V0L33.8721 1.27012e-05L33.8721 12.9829Z"
                              fill="#252F60"/>
                        <path
                            d="M102.741 32C102.741 36.5105 101.917 40.7162 100.269 44.6171C98.6199 48.5181 96.2691 51.901 93.2161 54.7657C90.2241 57.6305 86.6216 59.8857 82.4084 61.5314C78.2563 63.1771 73.6768 64 68.6699 64H43.6658V6.38676e-06H68.6699C73.6768 6.38676e-06 78.2563 0.822864 82.4084 2.46858C86.6216 4.05334 90.2241 6.2781 93.2161 9.14286C96.2691 12.0076 98.6199 15.3905 100.269 19.2914C101.917 23.1314 102.741 27.3067 102.741 31.8171V32ZM87.9954 32.1829V32C87.9954 29.1962 87.5069 26.6057 86.53 24.2286C85.6141 21.8514 84.3013 19.8095 82.5916 18.1029C80.943 16.3962 78.928 15.0857 76.5467 14.1714C74.1653 13.1962 71.5397 12.7086 68.6699 12.7086H57.7707V51.2914H68.6699C71.5397 51.2914 74.1653 50.8343 76.5467 49.92C78.928 49.0057 80.943 47.6952 82.5916 45.9886C84.3013 44.2819 85.6141 42.2705 86.53 39.9543C87.5069 37.6381 87.9954 35.0476 87.9954 32.1829Z"
                            fill="#252F60"/>
                    </svg>

                </div>
            </header>
        </>
    )
}