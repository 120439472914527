import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useEffect, useState} from "react";
import ProjectWidget from "./ProjectWidget";

export default function Projects(){
    gsap.registerPlugin(ScrollTrigger);
    useGSAP(() => {
        gsap.from("#webdesign", {
            y: -50,
            opacity: 0,
            delay: 0.5,
            duration: 1.5,
            ease: "power1.out"
        })
        gsap.from("#nom", {
            opacity: 0,
            duration: 2,
            ease: "power1.out"
        })

        gsap.from('#welcome', {
            x: "40%",
            scrollTrigger: {
                trigger: "#a-propos",
                scrub: true,
                toggleActions: "play none none reset"
            }
        })
    })

    const [projet, setProjet] = useState([]);
    const [filter, setFilter] = useState("");

    let filtered = projet.filter(c => c.class.includes(filter));

    async function getProjet() {
        try {
            const data = require("../data.json");
            setProjet(data)
        } catch (err) {
            console.error("error CharacterGroup/getMonsters", err)
        }
    }
    useEffect(() => {
        (async () => {
            await getProjet();
        })();
    }, []);

    function handleFilterChange(e) {
            setFilter(e.target.value)

    }
    return(
        <>
            <section id="accueil"
                     className="bg-fixed bg-center flex justify-center items-center h-screen bg-hero bg-cover bg-bottom-1">
                <div className="relative flex flex-col justify-center sm:text-center backdrop-blur-sm w-full h-full">
                    <h1 id="nom" className="text-h3 md:text-h2 lg:text-h1 text-white tracking-h1 uppercase font-bold ml-[32px] sm:ml-0">Mes créations</h1>
                    <h3 id="webdesign" className="text-nav md:text-h4 lg:text-h3 text-white tracking-[12px] sm:tracking-[20px] uppercase font-thin ml-[32px] sm:ml-0">Tous mes projets</h3>
                    <div className="absolute bottom-[0] bg-diag mt-[-32px] z-50 w-full h-[7.5vw]"></div>
                </div>
            </section>

            <section id="a-propos" className="mb-[160px]">
                <h1 id="welcome" className="overflow-x-hidden text-h3 md:text-h2 lg:text-h1 text-left ml-[-100px] -rotate-[4.5deg] mt-0 sm:mt-[-20px] md:mt-[-40px] lg:mt-[-55px] uppercase text-red whitespace-nowrap font-book font-light tracking-h1">Liste des tous mes projets.</h1>
                <div id="project" className="flex w-[90%] mx-auto mt-[160px] mb-[80px] flex-wrap justify-center">
                    <div className="block w-full text-center">
                        <select id="type" onChange={handleFilterChange} size="4" name="type" data-filter-value="class"
                                data-filter-compare="equal"
                                className="overflow-y-auto focus:outline-none w-[90%] flex mx-auto bg-white">
                            <option
                                className="text-h3 text-blue bg-red font-bold uppercase inline-block text-center mx-[64px]"
                                value="">Tous
                            </option>
                            <option
                                className="text-h3 text-blue font-bold uppercase inline-block text-center mx-[64px]"
                                value="Pro">Pro
                            </option>
                            <option
                                className="text-h3 text-blue font-bold uppercase inline-block text-center mx-[64px]"
                                value="études">Etudes
                            </option>
                            <option
                                className="text-h3 text-blue font-bold uppercase inline-block text-center mx-[64px]"
                                value="Autres">Autres
                            </option>
                        </select>
                    </div>
                    {filtered.map(x => <ProjectWidget key={x.name} {...x}/>)}
                </div>
            </section>
        </>
    )
}