import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default function Project(props){
    const article = props.article

    function createMarkup() {
        return { __html: article.description };
    }

    function createRole() {
        return { __html: article.role };
    }


    gsap.registerPlugin(ScrollTrigger);
    useGSAP(() => {

        let boutons2 = document.getElementsByClassName("cta2")
        for(let bouton of boutons2){
            bouton.onmouseover = () => {
                gsap.to(".cta2", {
                    background: "linear-gradient(to bottom left, rgba(204, 38, 78, 0) 0%, rgba(204, 38, 78, 0) 0%, #CC264E 0%, #CC264E 100%)",
                    textShadow: '-2px 2px 0 rgba(233, 234, 236, 1)',
                    duration: 0.5,
                    ease: "power1.out"
                })
            }

            gsap.to(".cta2", {
                background: "linear-gradient(to bottom left, rgba(204, 38, 78, 0) 0%, rgba(204, 38, 78, 0) 50%, #CC264E 50%, #CC264E 100%)",
                textShadow: '-1px 2px 0 rgba(204, 38, 78, 1)',
                duration: 0.5,
                ease: "power1.out"
            })

            bouton.onmouseout = () => {
                gsap.to(".cta2", {
                    background: "linear-gradient(to bottom left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #CC264E 50%, #CC264E 100%)",
                    duration: 0.5,
                    textShadow: '-1px 2px 0 rgba(204, 38, 78, 1)',
                    ease: "power1.out"
                })
            }
        }
        gsap.from("#webdesign", {
            y: -50,
            opacity: 0,
            delay: 0.5,
            duration: 1.5,
            ease: "power1.out"
        })
        gsap.from("#nom", {
            opacity: 0,
            duration: 2,
            ease: "power1.out"
        })

        gsap.from('#welcome', {
            x: "40%",
            scrollTrigger: {
                trigger: "#a-propos",
                scrub: true,
                toggleActions: "play none none reset"
            }

        })})
    return(
        <>
            <section id="accueil" className="bg-fixed bg-center flex justify-center items-center h-screen bg-hero bg-cover bg-bottom-1">
                <div className="relative flex flex-col justify-center sm:text-center backdrop-blur-sm w-full h-full">
                    <h1 id="nom" className="text-h3 md:text-h2 lg:text-h1 text-white tracking-h1 uppercase font-bold ml-[32px] sm:ml-0">{article.name}</h1>
                    <h3 id="webdesign" className="text-nav md:text-h4 lg:text-h3 text-white tracking-[12px] sm:tracking-[20px] uppercase font-thin ml-[32px] sm:ml-0">{article.categories}</h3>
                    <div className="absolute bottom-[0] bg-diag mt-[-32px] z-50 w-full h-[7.5vw]"></div>
                </div>
            </section>

            <section id="projet" className="w-80% mx-auto mb-[160px]">
                <h1 id="welcome"
                    className="overflow-x-hidden text-h3 md:text-h2 lg:text-h1 text-left ml-[-100px] -rotate-[4.5deg] mt-0 sm:mt-[-20px] md:mt-[-40px] lg:mt-[-55px] uppercase text-red whitespace-nowrap font-book font-light tracking-h1">{article.subtitle}</h1>
                <div className="my-[160px] flex flex-col lg:flex-row justify-between">
                    <div className="text-center lg:text-left">
                        <h4 className="text-h4 font-bold text-blue">Type de projet</h4>
                        <p className="text-blue mb-[64px]">{article.class}</p>
                        <h4 className="text-h4 font-bold text-blue">Statut du projet</h4>
                        <p className="text-blue mb-[64px]">{article.status}</p>
                        <h4 className="text-h4 font-bold text-blue">Nombre de participants</h4>
                        <p className="text-blue mb-[64px]">{article.group} personne(s) sur le projet</p>
                        <h4 className="text-h4 font-bold text-blue">Date du projet</h4>
                        <p className="text-blue mb-[64px]">{article.date}</p>
                    </div>
                    <div className="h-[450px]">
                        <img className="w-full lg:w-[600px] object-cover h-full rounded-[16px] border-[2px] border-red"
                             src={article.url}/>
                        <div className="cta2 relative inline-block px-[25px] py-[20px] mt-[24px] bg-cta"><a className="text-nav sm:text-h4 text-white lg:text-grey font-bold uppercase" href={article.link}>Voir le projet</a></div>
                    </div>
                </div>

                <div>
                    <h2 className="text-sub sm:text-h3 xl:text-h2 text-red uppercase tracking-h2">Description</h2>
                    <p className="text-[12px] sm:text-p text-blue font-regular text-justify mt-[24px]" dangerouslySetInnerHTML={createMarkup()}></p>
                    <h4 className="text-nav sm:text-h4 font-bold text-blue mt-[48px]">Mon rôle dans le projet</h4>
                    <p className="text-blue text-[12px] sm:text-p font-regular mt-[24px] text-justify" dangerouslySetInnerHTML={createRole()}></p>
                </div>
            </section>
        </>
    )
}