

export default function Skills(){
    let skills = ["/img/skills/html.svg",
        "/img/skills/css.svg",
        "/img/skills/js.svg",
        "/img/skills/php.svg",
        "/img/skills/wordpress.svg",
        "/img/skills/woocommerce.svg",
        "/img/skills/vuejs.svg",
        "/img/skills/react.svg",
        "/img/skills/vitejs.svg",
        "/img/skills/tailwindcss.svg",
        "/img/skills/sql.svg",
        "/img/skills/laravel.svg",
        "/img/skills/python.svg",
        "/img/skills/kotlin.svg",
        "/img/skills/docker.svg",
        "/img/skills/git.svg",
        "/img/skills/phpstorm.svg",
        "/img/skills/androidstudio.svg",
        "/img/skills/figma.svg",
        "/img/skills/office.svg",
        "/img/skills/photoshop.svg",
        "/img/skills/illustrator.svg",
        "/img/skills/premierepro.svg",
        "/img/skills/aftereffects.svg",
        "/img/skills/adobexd.svg"]
    return (
        <div className="relative mt-[160px] flex w-[75%] lg:w-[800px] mx-auto justify-center flex-wrap">
            <img id="skills" className="absolute w-[120%] md:w-full top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]" src="/img/skills.svg"/>
            {skills.map(x => <div
                className="drop-shadow-img hover:rounded-[32px] transition-all bg-grey w-[72px] h-[72px] md:w-[96px] md:h-[96px] lg:w-[128px] lg:h-[128px] flex items-center justify-center rounded-[16px] m-[8px]">
                <img className=" w-[48px] h-[48px] md:w-[72px] md:h-[72px] lg:w-[96px] lg:h-[96px] " src={x}/></div>)}
        </div>)
}